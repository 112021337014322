import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function PartnerPrivacyPolicy() {
  return (
    <>
      <Header />
      <div style={{ backgroundColor: "white" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <style>
                {`li { color: black; }`}
              </style>
              <h1 style={{ color: "black" }}>Partner Privacy Policy</h1>
              <p>
                <strong>Effective Date: 20/09/2024</strong>
              </p>

              <p>
                Safaixyz ("we", "our", "us") operates the Safaixyz Partner App
                and website (<a href="https://www.safaixyz.com">www.safaixyz.com</a>), which
                allows cleaning service providers to manage bookings and connect with users.
                We are committed to protecting your personal data and ensuring transparency
                about how we collect, use, and safeguard your information. This
                Privacy Policy applies to all partners using our platform.
              </p>

              <h2>1. Information We Collect</h2>
              <p>
                We collect the following types of information when you use our
                Partner App:
              </p>
              <ol>
                <li>
                  <strong>Personal Information:</strong>
                  <ul>
                    <li>Full Name</li>
                    <li>Email Address</li>
                    <li>Phone Number</li>
                    <li>Business Address</li>
                  </ul>
                </li>
                <li>
                  <strong>Business Information:</strong>
                  <ul>
                    <li>Service Category</li>
                    {/* <li>Experience and Certifications</li> */}
                    <li>Service Area Preferences</li>
                  </ul>
                </li>
                <li>
                  <strong>Usage Data:</strong>
                  <ul>
                    <li>Date and time of service bookings</li>
                    <li>Device Token (for notifications)</li>
                  </ul>
                </li>
              </ol>

              <h2>2. How We Use Your Information</h2>
              <p>We use the collected information to:</p>
              <ol>
                <li>Enable service bookings and customer interactions</li>
                {/* <li>Facilitate payments and transactions</li> */}
                <li>Send notifications and service updates</li>
                <li>Verify and authenticate partners</li>
                <li>Comply with legal and regulatory requirements</li>
              </ol>

              <h2>3. Sharing Your Information</h2>
              <p>
                We do not sell or rent your personal information to third parties. However, we may share your data with:
              </p>
              <ol>
                <li>
                  <strong>Customers:</strong> Your profile details (name, verification documentation
                  and contact information) may be shared with users for booking purposes.
                </li>
                {/* <li>
                  <strong>Payment Providers:</strong> To process transactions securely.
                </li> */}
                <li>
                  <strong>Legal Authorities:</strong> If required by law or regulatory compliance.
                </li>
              </ol>

              <h2>4. Data Security</h2>
              <p>
                We take appropriate measures to protect your data from unauthorized access or disclosure.
              </p>

              <h2>5. Your Rights</h2>
              <p>You have the right to:</p>
              <ol>
                <li>Access and review your personal information</li>
                <li>Update or correct your data</li>
                <li>Request deletion of your account, subject to conditions</li>
                <li>Withdraw consent for data processing</li>
              </ol>
              <p>
                To exercise these rights, please contact us at
                <a href="mailto:helpdesk@safaixyz.com"> helpdesk@safaixyz.com</a>.
              </p>

              <h2>6. Cookies and Tracking Technologies</h2>
              <p>
                We may use cookies and similar tracking technologies to enhance your app experience.
              </p>

              <h2>7. Changes to this Privacy Policy</h2>
              <p>
                We may update this policy periodically. Any changes will be notified within the app.
              </p>

              <h2>8. Contact Us</h2>
              <p>
                If you have any questions or concerns, please contact us at:
              </p>
              <p>
                Email: <a href="mailto:helpdesk@safaixyz.com">helpdesk@safaixyz.com</a>
                <br />
                Website: <a href="https://www.safaixyz.com">www.safaixyz.com</a>
                <br />
                Phone: +91-8006305606
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PartnerPrivacyPolicy;
